import { observable, action } from 'mobx';
import GlobalMetaTagsService from "services/globalMetaTags.service";
import {GlobalMetaTags} from "models";

class GlobalMetaTagsStore {
    @observable metaTags: GlobalMetaTags | any = null;

    @action.bound
    async getMetaTagsData() {
        this.metaTags = await GlobalMetaTagsService.fetchMetaTagsData();
    }
}

export default GlobalMetaTagsStore;
