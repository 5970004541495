import get from 'lodash/get';
import ContentItemWithResolver from "models/ContentItemWithResolver";

export default class GlobalMetaTag extends ContentItemWithResolver {
    get name() {
        return get(this, '_name.value', '');
    }

    get content() {
        return get(this, '_content.value', '');
    }
}
