import React, { Component } from 'react';
import LinkParser from 'components/LinkParser/LinkParser';
import Button from 'components/Buttons/Button/Button';
import styles from './CtaContactSection.module.scss';
import { CtaContactSection as CtaContactSectionModel } from 'models';

export interface CtaContactSectionProps {
  data: CtaContactSectionModel;
}
class CtaContactSection extends Component<CtaContactSectionProps> {

  render() {
    const {
      buttonText,
      header,
      text,
      buttonLink,
    } = this.props.data;
    return (
      <div className={styles.Container}>
        <h3 className={styles.Header}>{header}</h3>
        <p className={styles.Text}>{text}</p>
        <LinkParser
          data={buttonLink}
          className={styles.ContactNavButton}
        >
          <Button type="button">
            {buttonText}
          </Button>
        </LinkParser>
      </div>
    );
  }
}

export default CtaContactSection as any;
