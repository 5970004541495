import React, { Component } from 'react';
import styles from './ShortListing.module.scss';
import { ShortListing as ShortListingModel } from 'models';
import Layout from 'components/Layout/Layout';
import { ArticleBox } from 'blocks/ArticleBox/ArticleBox';
import ANCHOR from 'common/consts/anchor';

interface CaseStudiesShortListingProps {
  data: ShortListingModel;
}

class CaseStudiesShortListing extends Component<CaseStudiesShortListingProps> {
  getAnchor(anchorType) {
    switch (anchorType) {
      case 'case_study':
        return ANCHOR.CASE_STUDIES;
      case 'blog':
        return ANCHOR.BLOG;
      default:
        return ANCHOR.HOME;
    }
  }

  render() {
    const {
      data: { heading, description, caseStudies, anchorType },
    } = this.props;

    return (
      <Layout className={styles.Container}>
        <h2 className={styles.Heading}>{heading}</h2>
        {description && <p className={styles.Description}>{description}</p>}
        <section className={styles.ArticleBoxesContainer}>
          {caseStudies.map((articleData) => (
            <ArticleBox
              className={styles.ArticleBox}
              data={articleData}
              key={articleData.id}
              anchor={this.getAnchor(anchorType)}
            />
          ))}
        </section>
      </Layout>
    );
  }
}

export default CaseStudiesShortListing as any;
