import React, { useState } from 'react';
import DownButton from 'components/Buttons/Arrow/DownButton';
import styles from './Accordion.module.scss';

interface AccordionProps {
  title: string;
  description: string;
}

const Accordion: React.FC<AccordionProps> = (props) => {
  const { title, description } = props;
  const [collapsedColumn, handleColapsedColumn] = useState(true);

  return (
    <div className={styles.Container}>
      <div className={styles.FlexContainer}>
        <h3
          onClick={() => handleColapsedColumn(!collapsedColumn)}
          className={styles.Title}
        >
          {title}
        </h3>
        <DownButton
          onClick={() => handleColapsedColumn(!collapsedColumn)}
          className={`${styles.CarouselArrow} ${!collapsedColumn &&
            styles.CarouselArrowOpened}`}
          style={{ borderWidth: '0 4px 4px 0' }}
        />
      </div>
      {!collapsedColumn && <p className={styles.Description}>{description}</p>}
    </div>
  );
};

export default Accordion;
