import React, { FunctionComponent, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import noop from 'lodash/noop';

import { SEO as Model } from 'models';
import GlobalMetaTagsStore from "stores/globalMetaTags.store";

import LANGUAGES from 'common/consts/languages';
import LanguageService from 'services/language.service';

export interface SEOProps {
	data: Model;
	slug: string;
	globalMetaTagsStore?: GlobalMetaTagsStore;
}

const SEO: FunctionComponent<SEOProps> = inject('globalMetaTagsStore')(observer((props) => {
	const {
		data: {
			title,
			description,
			ogImage,
			ogType,
			metaKeywords,
			noIndex,
		},
		slug,
		globalMetaTagsStore: {
			metaTags = null,
			getMetaTagsData = noop,
		} = {},
	} = props;

	useEffect(() => {
		getMetaTagsData();
	}, []);

	const renderCanonical = () => {
		return (
			<link
				rel="canonical"
				href={document.location.href}
			/>
		)
	};

	const renderHreflangs = () => {
		const languages = Array.from(LANGUAGES.values());
		if (!slug) {
			return (
				languages.map((language) => {
					const isDefaultLanguage = language === LanguageService.DEFAULT_LANGUAGE;
					return (
						<link
							key={language}
							rel="alternate"
							hrefLang={language}
							href={`${document.location.origin}/${!isDefaultLanguage ? language : ''}`}
						/>
					);
				})
			);
		}
		const currentLanguage = LanguageService.getSelectedLanguage();
		return (
			<link
				rel="alternate"
				hrefLang={currentLanguage}
				href={document.location.href}
			/>
		);
	};

	const renderGlobalMetaTags = () =>
		metaTags ?
			metaTags.metaTags.map(({ id, name, content }) =>
				<meta key={id} name={name} content={content} />)
			:
			null;

	return (
		<Helmet defaultTitle="LegalHut" titleTemplate="%s">
			{title && (
				<title>
					{ title }
				</title>)}
			{description && <meta name="description" content={description} />}
			{noIndex && <meta name="robots" content={noIndex} />}
			{title && <meta property="og:title" content={title} />}
			{ogType && <meta property="og:type" content={ogType} />}
			{ogImage && <meta property="og:image" content={ogImage} />}
			{metaKeywords && <meta property="keywords" content={metaKeywords} />}
			{description && (
				<meta property="og:description" content={description} />
			)}
			<meta name="twitter:card" content="summary" />
			{renderHreflangs()}
			{renderCanonical()}
			{renderGlobalMetaTags()}
		</Helmet>
	);
}));

export default SEO;
