import React, { FunctionComponent } from 'react';
import { BlogArticle } from 'models';
import Asset from 'components/Asset/Asset';
import { Tag } from 'components/Tag/Tag';
import ANCHOR from 'common/consts/anchor';
import { Markup } from 'components/Markup/Markup';
import {
  NBSPAfterSingleLetters,
  underscoresToDashes,
} from 'common/methods/string';
import styles from './ArticleBox.module.scss';
import { LawTag } from 'components/LawTag/LawTag';

export interface ArticleBoxProps {
  data: BlogArticle;
  className?: string;
  anchor?: string;
}

export const ArticleBox: FunctionComponent<ArticleBoxProps> = ({
  data,
  className,
  anchor,
}) => {
  const category = underscoresToDashes(data.mainCategory.codename);

  return (
    <a
      className={`${className} ${styles.Container}`}
      href={`${anchor ? anchor : ANCHOR.BLOG}/${category}/${data.slug}`}
    >
      <div className={styles.RelativeAssetContainer}>
        <Asset
          className={styles.ImageContainer}
          assetClassName={styles.Image}
          data={data.image}
        />
        <div className={styles.Tags}>
          {data.categories.map((categoryItem) => (
            <Tag className={styles.Tag}>{categoryItem.name}</Tag>
          ))}
        </div>
      </div>
      <h2 className={styles.Title}>
        <Markup wrap={false} markup={NBSPAfterSingleLetters(data.title)} />
      </h2>
      {data.lawCategories.length !== 0 && (
        <div className={styles.LawTags}>
          {data.lawCategories.map((tag) => (
            <LawTag>{tag.name}</LawTag>
          ))}
        </div>
      )}
      <p className={styles.BottomText}>
        <Markup wrap={false} markup={NBSPAfterSingleLetters(data.lead)} />
      </p>
    </a>
  );
};

ArticleBox.defaultProps = {
  className: '',
};
