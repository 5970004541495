import ContentItemWithResolver from 'models/ContentItemWithResolver';
import get from 'lodash/get';

export default class AccordionSection extends ContentItemWithResolver {
  get heading() {
    return get(this, '_heading.value', '');
  }

  get description(){
      return get (this, '_description.value', '')
  }

  get accordion(){
      return get(this, '_accordion_elements', [])
  }

}
