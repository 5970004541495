import ContentItemWithColorPickers from 'models/ContentItemWithColorPickers';
import get from 'lodash/get';

export default class HelpSectionSlider extends ContentItemWithColorPickers {
  get heading() {
    return get(this, '_heading.value', '');
  }

  get description() {
    return get(this, '_description.value', '');
  }

  get comment() {
    return get(this, '_comment.value', '');
  }

  get slides() {
    return get(this, '_slides', []);
  }
}
