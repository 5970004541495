import React, { FunctionComponent } from 'react';
import RichTextParser from 'components/RichTextParser/RichTextParser';
import styles from './CollaborationCard.module.scss';
import LinkParser from 'components/LinkParser/LinkParser';
import { InnerLink } from 'models';
import Button from 'components/Buttons/Button/Button';

export interface CollaborationCard {
  title: string,
  backgroundImage: string,
  text: string,
  image: string,
  link?:InnerLink,
}

const CollaborationCard: FunctionComponent<any> = ({ title, backgroundImage, text, image, link }) => (
  <div className={styles.wrapper}>
  <div className={ link ? styles.containerWithLink : styles.container} style={{ backgroundImage: `url(${backgroundImage})`}}>
    <div className={styles.wrapperImage}>
      <img src={image} alt={title} className={styles.image} />
    </div>
    <RichTextParser content={title} className={styles.title} />
    <RichTextParser content={text} />
    { link && <LinkParser className={styles.Link} data={link}>
      <Button className={styles.ReadMore}>
        {link.text}
      </Button>
    </LinkParser> }
  </div>
  </div>
);

CollaborationCard.defaultProps = {
	className: '',
};

export default CollaborationCard;
