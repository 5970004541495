import React, { Component } from 'react';
import { SectionWithHeader as SectionWithHeaderModel } from 'models/index';
import { inject, observer } from 'mobx-react';
import WindowStore from 'stores/window.store';
import CoreStore from 'stores/core.store';
import styles from './SectionWithHeader.module.scss';
import Layout from 'components/Layout/Layout';
import { setColor } from 'components/Background/Background';
interface SectionWithHeaderProps {
  data: SectionWithHeaderModel;
  windowStore: WindowStore;
  coreStore: CoreStore;
}

@inject('windowStore', 'coreStore')
@observer
class SectionWithHeader extends Component<SectionWithHeaderProps> {
  render() {
    const {
      data: {
        description,
        heading,
        image,
        contentReversed,
        textColor,
        backgroundAnimatedElement,
      },
      windowStore,
    } = this.props;
    const reversed = contentReversed === 'reversed';

    return (
      <div className={reversed ? styles.ContainerReversed : styles.Container}>
        {windowStore.mediaSmallDesktop && backgroundAnimatedElement.url && (
          <img
            src={backgroundAnimatedElement.url}
            className={reversed ? styles.BackgroundReversed : styles.Background}
          />
        )}
        <Layout>
          <div className={styles.GridContainer}>
            {heading && (
              <h2
                style={{ color: setColor(textColor) }}
                className={reversed ? styles.HeadingReversed : styles.Heading}
              >
                {heading}
              </h2>
            )}
            <p
              className={
                reversed ? styles.DescriptionReversed : styles.Description
              }
            >
              {description}
            </p>
            <img
              className={reversed ? styles.ImageReversed : styles.Image}
              src={image.url}
              alt={image.name}
            />
          </div>
        </Layout>
      </div>
    );
  }
}

export default SectionWithHeader as any;
