import React, { Component } from 'react';
import { AccordionSection as AccordionSectionModel } from 'models';
import { inject, observer } from 'mobx-react';
import WindowStore from 'stores/window.store';
import styles from './AccordionSection.module.scss';
import Layout from 'components/Layout/Layout';
import { observable } from 'mobx';
import Accordion from 'components/Accordion/Accordion';

interface AccordionSectionProps {
  data: AccordionSectionModel;
  windowStore: WindowStore;
}

@inject('windowStore')
@observer
class AccordionSection extends Component<AccordionSectionProps> {
  activeKey = observable.box(null);

  render() {
    const {
      data: { heading, description, accordion },
      windowStore,
    } = this.props;

    return (
      <div className={styles.Container}>
        <Layout>
          <h2 className={styles.Heading}>{heading}</h2>
          <p className={styles.Description}>{description}</p>
          <div className={styles.Accordion}>
            {accordion.map((contentItem, i) => (
              <Accordion
                key={i}
                title={contentItem.title.value}
                description={contentItem.description.value}
              />
            ))}
          </div>
        </Layout>
      </div>
    );
  }
}

export default AccordionSection as any;
