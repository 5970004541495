import get from 'lodash/get';
import InnerLink from './InnerLink';

export default class CollaborationColumn {
  title: string;
  image: string;
  backgroundImage: string;
  text: string;
  link:InnerLink;

  constructor(collColumn) {
    this.title = get(collColumn, 'collaboration_column[0].elements.header_text.value', '');
    this.image = get(collColumn, 'collaboration_column[0].elements.header_image.value[0].url', '');
    this.backgroundImage = get(collColumn, 'background_image.value[0].url', '');
    this.text = get(collColumn, 'collaboration_column[0].elements.text.value', '');
    this.link = get(collColumn, 'collaboration_column[0]._link[0]', null)
  }
}
