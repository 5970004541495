import { KENTICO_TYPES } from 'common/consts/kentico';
import Client from './kenticoClient';

class GlobalMetaTagsService {
    static async fetchMetaTagsData() {
        const items = await Client.items()
            .type(KENTICO_TYPES.GLOBAL_META_TAGS)
            .getPromise();

        if (items.isEmpty) {
            return null;
        }

        return items.lastItem;
    }
}

export default GlobalMetaTagsService;
