import React from 'react';
import styles from './DiamondSlide.module.scss';

interface DiamondSlideProps {
  slideNumber: number;
  description: string;
  asset: string;
  arrow?: string;
}

const DiamondSlide: React.FC<DiamondSlideProps> = ({
  slideNumber,
  description,
  asset,
  arrow,
}) => {
  return (
    <div className={styles.Container}>
      <div className={styles.AssetWrapper}>
        <img
          style={{ margin: '0' }}
          className={styles.Asset}
          src={asset}
          alt={`${slideNumber}`}
        />
        <p className={styles.SlideNumber}>{slideNumber}</p>
        {arrow && <img className={styles.Arrow} src={arrow} alt="arrow-left" />}
      </div>
      <p className={styles.Description}>{description}</p>
    </div>
  );
};

export default DiamondSlide;
