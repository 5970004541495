import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import SEO from 'blocks/SEO/SEO';
import { ROUTER_PARAM } from 'common/consts/common';
import SLUG from 'common/consts/slug';
import LanguageService from 'services/language.service';
import ViewStore from 'stores/view.store';
import CoreStore from 'stores/core.store';
import BlogStore from 'stores/blog.store';
import { ViewContentParser } from './ViewContentParser';

export interface ViewProps {
  viewStore: ViewStore;
  coreStore: CoreStore;
  blogStore: BlogStore;
  slug?: string;
  path?: string;
  match: any;
}

@inject('viewStore', 'blogStore', 'coreStore')
@observer
class View extends Component<ViewProps> {
  constructor(props: ViewProps) {
    super(props);
    this.loadView();
  }

  componentDidUpdate(prevProps) {
    const category = this.props.match.params[ROUTER_PARAM.CATEGORY] || '';
    const slug = this.props.match.params[ROUTER_PARAM.SLUG] || '';
    const prevSlug = prevProps.match.params[ROUTER_PARAM.SLUG] || '';
    if (category) {
      this.props.blogStore.validateCategory(category);
    }
    if (slug !== prevSlug) {
      this.loadView();
    }
  }

  loadView() {
    const { viewStore } = this.props;

    const currentSlug = this.props.match.params[ROUTER_PARAM.SLUG] || '';

    const currentSlugKey = Object.keys(SLUG).find((slugObjKey) => {
      const slugObject = SLUG[slugObjKey];
      return Object.keys(slugObject).some(
        (slugItemKey) => slugObject[slugItemKey] === currentSlug
      );
    });
    const currentSlugObject = currentSlugKey ? SLUG[currentSlugKey] : null;
    const currentLang = LanguageService.getSelectedLanguage();
    const isSlugProper =
      currentSlug === '' ||
      (currentSlugObject && currentSlugObject[currentLang] === currentSlug);
    if (isSlugProper) {
      viewStore.loadView(
        currentSlug === ''
          ? currentSlug
          : currentSlugObject[LanguageService.EN_LANGUAGE]
      );
      return;
    }
    this.props.coreStore.setContainerFound(false);
  }

  render() {
    const {
      viewStore: { currentView, isViewLoading },
    } = this.props;
    const slug = this.props.match.params.slug || '';
    if (!currentView || isViewLoading) {
      return null;
    }

    return (
      <div>
        {/* <GridVisual /> */}
        {currentView && currentView.SEO && (
          <SEO data={currentView.SEO} slug={slug} />
        )}
        {currentView && <ViewContentParser content={currentView.content} />}
      </div>
    );
  }
}

export default View as any;
