import React, { FunctionComponent, useMemo } from 'react';
import LANGUAGES, { 
    POLISH,
   } from 'common/consts/languages';
import styles from './Languages.module.scss';

export interface LanguagesProps {
    language?: string;
    setLanguage?: (lang) => any;
    isMobile?: boolean;
}

const Languages: FunctionComponent<LanguagesProps> = ({ language, setLanguage, isMobile }) => {
    const slug = useMemo(() => language === POLISH ? '/' : `/${LANGUAGES.get('EN')}`, [language]);
    
    return(
            <a
                href={`${slug}`}
                onClick={setLanguage}
                className={`${styles.Language} ${isMobile && styles.MobileView}`} 
            >
                {language}
            </a>
    )};

export default Languages;
