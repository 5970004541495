import React, { Component } from 'react';
import { Navigator as NavigatorModel } from 'models';
import { observer, inject } from 'mobx-react';
import NavigatorStore from 'stores/navigator.store';
import styles from './Navigator.module.scss';

export interface NavigatorProps {
	navigatorStore: NavigatorStore;
	data: NavigatorModel;
}

@inject('navigatorStore')
@observer
class Navigator extends Component<NavigatorProps> {
	navigatorRef: HTMLElement | null = null;

	componentDidMount() {
		const {
			navigatorStore,
			data: {
				id,
			},
		} = this.props;
		if (this.navigatorRef) {
			navigatorStore.addNavigator(id, this.navigatorRef);
		}
	}

	render() {
		return (
			<span
				className={styles.Navigator}
				ref={(navigatorRef) => this.navigatorRef = navigatorRef}
			/>
		);
	}
}

export default Navigator as any;
