import React, { FunctionComponent } from 'react';
import LinkedinLogo from 'assets/linkedin';
import TwitterLogo from 'assets/tt';
import FacebookLogo from 'assets/fb';

import styles from './SocialMedia.module.scss';

export interface SocialMediaProps {
  title?: string,
  ttUrl?: string,
  liUrl?: string,
  fbUrl?: string,
  className?: string;
}

const SocialMedia: FunctionComponent<SocialMediaProps> = ({ title, ttUrl, liUrl, fbUrl, className }) => (
  <div className={`${className} ${styles.Container}`}>
    <div className={styles.Wrapper}>
      {title && <div className={styles.Title}>{title}<span className={styles.Separator}>|</span></div>}
      <div className={styles.SocialMedias}>
        {ttUrl &&
          <a
            href={ttUrl}
            target='_blank'
            rel='noopener noreferrer nofollow'
          >
            <TwitterLogo />
          </a>
        }
        {liUrl &&
          <a
            href={liUrl}
            target='_blank'
            rel='noopener noreferrer nofollow'
          >
            <LinkedinLogo />
          </a>
        }
        {fbUrl &&
          <a
            href={fbUrl}
            target='_blank'
            rel='noopener noreferrer nofollow'
          >
            <FacebookLogo />
          </a>
        }
      </div>
    </div>
  </div>
);

export default SocialMedia;
