import { observable, action, reaction } from "mobx";
import { Navigator as NavigatorModel } from "models";
import root from "window-or-global";
import { Bind, Debounce } from "lodash-decorators";
import { DEFAULT_LANGUAGE_ANCHOR } from "common/consts/languages";

class NavigatorStore {
  @observable navigators = new Map<string, HTMLElement | null>();

  @observable activeNavigatorId: any;

  constructor(private coreStore) {
    root.addEventListener("resize", this.onViewportChange, { passive: true });
    root.addEventListener("scroll", this.onViewportChange, { passive: true });
  }

  @Bind
  @Debounce(100)
  private onViewportChange() {
    let difference: number;
    let activeNavigatorId = "";
    this.navigators.forEach((ref: unknown, id: string) => {
      const navigator: HTMLSpanElement = ref as HTMLSpanElement;
      if (root.scrollY + 1 >= navigator.offsetTop) {
        const newDifference = root.scrollY + 1 - navigator.offsetTop;
        if (difference === null || difference > newDifference) {
          difference = newDifference;
          activeNavigatorId = id;
        }
      }
    });
    this.activeNavigatorId = activeNavigatorId;
  }

  @Bind
  navigate(anchor: string, navigator?: NavigatorModel) {
    const sameUrl =
      this.coreStore.history.location.pathname === anchor ||
      this.coreStore.history.location.pathname === DEFAULT_LANGUAGE_ANCHOR;

    if (!sameUrl) {
      if (navigator) {
        const disposeNavigateReaction = reaction(
          () => this.navigators.size,
          () => {
            if (this.navigateToNavigatorElement(navigator, !sameUrl)) {
              disposeNavigateReaction();
            }
          }
        );
      }
      this.coreStore.history.push(anchor);
      this.clearNavigators();
    }
else{
    if (navigator) {
      if (!this.navigateToNavigatorElement(navigator, !sameUrl)) {
        const disposeNavigateReaction = reaction(
          () => this.navigators.size,
          () => {
            if (this.navigateToNavigatorElement(navigator, !sameUrl)) {
              disposeNavigateReaction();
            }
          }
        );
      }
    } else {
      if (sameUrl) {
        root.document.body.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      } else {
        root.scrollTo(0, 0);
      }
    }
  }
  }

  @action.bound
  private navigateToNavigatorElement(
    navigator: NavigatorModel,
    instantScroll: boolean
  ): boolean {
    const navigatorElement = this.findNavigator(navigator.id);
    if (navigatorElement) {
      navigatorElement.scrollIntoView({
        behavior: instantScroll ? "auto" : "smooth",
        block: "start",
      });
      return true;
    }
    return false;
  }
  @action.bound
  addNavigator(id: string, navigatorRef: HTMLElement | null) {
    this.navigators.set(id, navigatorRef);
  }

  @action.bound
  removeNavigator(id: string) {
    this.navigators.delete(id);
  }

  @action.bound
  clearNavigators() {
    this.navigators.clear();
  }

  findNavigator(id: string): HTMLSpanElement {
    return (this.navigators.get(id) as unknown) as HTMLSpanElement;
  }
}

export default NavigatorStore;
