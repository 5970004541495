import get from 'lodash/get';
import ContentItemWithColorPickers from "models/ContentItemWithColorPickers";

export default class FooterSection extends ContentItemWithColorPickers {
  get description(): string {
    return get(this, '_description.value', '');
  }

  get linksHeader(): string {
    return get(this, '_links_header.value', '');
  }

  get links(): any[] {
    return get(this, '_links', []);
  }

  get servicesLinksHeader(): string {
    return get(this, '_services_links_header.value', '');
  }

  get servicesLinks(): any[] {
    return get(this, '_services_links', []);
  }

  get bottomLinks(): any[] {
    return get(this, '_bottom_links', []);
  }

  get bottomText(): string {
    return get(this, '_bottom_text.value', '');
  }

  get mobileBottomText(): string {
    return get(this, '_mobile_bottom_text.value', '');
  }

  get logo() {
    return get(this, '_logo.value[0]', '');
  }

  get FindUsText() {
    return get(this, '_text.value', '');
  }

  get twitterUrl(): string {
    return get(this, '_twitter_url.value', '');
  }

  get linkedInUrl(): string {
    return get(this, '_linkedin_url.value', '');
  }

  get facebookUrl(): string {
    return get(this, '_facebook_url.value', '');
  }
}
