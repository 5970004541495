import React, { Component } from 'react';
import styles from './HeroLanding.module.scss';
import { HeroLanding as HeroLandingModel } from 'models';
import WindowStore from 'stores/window.store';
import CoreStore from 'stores/core.store';
import { inject, observer } from 'mobx-react';
import Layout from 'components/Layout/Layout';
import LinkParser from 'components/LinkParser/LinkParser';

interface HeroLandingProps {
  data: HeroLandingModel;
  windowStore: WindowStore;
  coreStore: CoreStore;
}

@inject('windowStore', 'coreStore')
@observer
class HeroLanding extends Component<HeroLandingProps> {
  render() {
    const {
      windowStore,
      coreStore,
      data: { backgroundAsset, buttonLinks, description, heading },
    } = this.props;

    return (
      <div className={styles.Wrapper}>
        <img
          src={backgroundAsset.url}
          alt={backgroundAsset.name}
          className={styles.BackgroundImage}
        />

        <Layout>
          <div className={styles.Container}>
            <h1 className={styles.Heading}>{heading.text}</h1>
            <p className={styles.Description}>{description.text}</p>
            {buttonLinks.map((btnLink) => (
              <LinkParser data={btnLink}>
                <button className={styles.BtnLink}>{btnLink.text}</button>
              </LinkParser>
            ))}
          </div>
        </Layout>
      </div>
    );
  }
}

export default HeroLanding as any;
