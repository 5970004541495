import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import CoreStore from 'stores/core.store';
import WindowStore from 'stores/window.store';
import Asset from 'components/Asset/Asset';
import LinkParser from 'components/LinkParser/LinkParser';
import LinkedInLink from 'components/SocialLinks/LinkedInLink';
import YoutubeLink from 'components/SocialLinks/YoutubeLink';
import FacebookLink from 'components/SocialLinks/FacebookLink';
import Button from 'components/Buttons/Button/Button';
import Languages from 'components/Languages/Languages';
import LanguageService from 'services/language.service';
import OuterLink from 'models/OuterLink';
import { Bind } from 'lodash-decorators';
import NavigatorStore from 'stores/navigator.store';
import ANCHOR from 'common/consts/anchor';
import styles from './DesktopNav.module.scss';
import root from 'window-or-global';


export interface DesktopNavProps {
	contactButtonLink: any;
	linkedInLink: OuterLink;
	youTubeLink: OuterLink;
	facebookLink: OuterLink;
	coreStore: CoreStore;
	windowStore: WindowStore;
	navigatorStore: NavigatorStore;
	className?: string;
	links: any[];
}

@inject('coreStore', 'navigatorStore', 'windowStore')
@observer
class DesktopNav extends Component<DesktopNavProps> {

	@Bind
	navigateHome() {
		const { navigatorStore } = this.props;
		navigatorStore.navigate(ANCHOR.HOME);
		root.location.replace(ANCHOR.HOME);
	}

	render() {
		const {
			coreStore: {
				data,
			},
			contactButtonLink,
			linkedInLink,
			youTubeLink,
			facebookLink,
			className,
			links,
		} = this.props;
		return (
			<div className={`${styles.Navbar} ${className}`}>
				<Asset
					className={styles.LogoContainer}
					assetClassName={styles.Logo}
					data={data.logo}
					onClick={this.navigateHome}
				/>
				<div className={styles.WhiteBackgroundShow} />
				<div className={styles.NavButtons}>
					<ul className={styles.MainLinksContainer}>
						{links.map((link) => (
							<li className={styles.MainLink} key={link.text}>
								<LinkParser data={link} >
									{link.text}
								</LinkParser>
							</li>
						))}
					</ul>

					<div className={styles.SocialIcons}>
						{linkedInLink.link && (
							<LinkedInLink
								className={styles.Icon}
								data={linkedInLink}
							/>
						)}
						{youTubeLink.link && (
							<YoutubeLink
								className={`${styles.Icon} ${styles.LowerIcon}`}
								data={youTubeLink}
							/>
						)}
						{facebookLink.link && (
							<FacebookLink
								data={facebookLink}
								className={styles.Icon}
							/>
						)}
					</div>
					{!LanguageService.isBlogPathName() && !LanguageService.isCaseStudiesPathName() && <Languages
						language={LanguageService.getCurrentLanguage()}
						setLanguage={() => LanguageService.setCurrentLanguage()}
					/>}
					<LinkParser
						data={contactButtonLink}
						className={styles.ContactNavButton}
					>
						<Button type="button">
							{contactButtonLink.text}
						</Button>
					</LinkParser>
				</div>
			</div>
		);
	}
}

export default DesktopNav as any;
