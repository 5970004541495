import React, { Component, createRef } from 'react';
import styles from './HelpSectionSlider.module.scss';
import { HelpSectionSlider as HelpSectionSliderModel } from 'models';
import { setColor } from 'components/Background/Background';
import { isMobile } from 'react-device-detect';
import Layout from 'components/Layout/Layout';
import { SlideRef } from 'common/types/types';
import Carousel from 'components/Carousel/Carousel';
import DiamondSlide from 'components/DiamondSlide/DiamondSlide';
import get from 'lodash/get';

interface HelpSectionSliderProps {
  data: HelpSectionSliderModel;
}

class HelpSectionSlider extends Component<HelpSectionSliderProps> {
  state = {
    currentSlideIndex: 0,
  };

  slideRef = createRef<SlideRef>();

  sliderSettings = {
    className: styles.PartnersContainer,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplaySpeed: 2500,
    infinite: true,
    autoplay: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  setCurrentSlide(slideIndex: number) {
    this.setState({ currentSlideIndex: slideIndex });
    if (this.slideRef && this.slideRef.current) {
      this.slideRef.current.slickGoTo(slideIndex);
    }
  }

  render() {
    const {
      data: { heading, description, comment, backgroundColor, slides },
    } = this.props;

    return (
      <div
        style={{ backgroundColor: setColor(backgroundColor) }}
        className={styles.Container}
      >
        <Layout>
          <h2 className={styles.Heading}>{heading}</h2>
          <p className={styles.Description}>{description}</p>
          <h4 className={styles.Comment}>{comment}</h4>
          <Carousel
            {...this.sliderSettings}
            slideRef={this.slideRef}
            beforeChange={(before: number, next: number) => {
              this.setState({ currentSlideIndex: next });
            }}
            draggable={isMobile}
            className={styles.DiamondSections}
          >
            {slides.map((slide, index) => (
              <DiamondSlide
                key={index}
                asset={get(slide, 'main_image.value[0].url', '')}
                description={get(slide, 'description.value', '')}
                arrow={get(slide, 'arrow.value[0].url', '')}
                slideNumber={index + 1}
              />
            ))}
          </Carousel>
        </Layout>
      </div>
    );
  }
}

export default HelpSectionSlider as any;
