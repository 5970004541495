import get from 'lodash/get';
import ContentItemWithColorPickers from 'models/ContentItemWithColorPickers';
import VoiceRecorder from 'models/VoiceRecorder';
import {Fields} from "kentico-cloud-delivery/_es2015";
type RichTextField = Fields.RichTextField;

export default class ContactFormSection extends ContentItemWithColorPickers {
	get headerText(): string {
		return get(this, '_header_text.value', '');
	}

	get headingText(): string {
		return get(this, '_heading_text.value', '');
	}

	get contactDetailsHeading(): string {
		return get(this, '_contact_details_heading.value', '');
	}

	get contactDetails(): RichTextField {
		return get(this, '_contact_details', '');
	}

	get nameLabel(): string {
		return get(this, '_name_label.value', '');
	}

	get companyLabel(): string {
		return get(this, '_company_label.value', '');
	}

	get phoneLabel(): string {
		return get(this, '_phone_label.value', '');
	}

	get emailLabel(): string {
		return get(this, '_email_label.value', '');
	}

	get messageLabel(): string {
		return get(this, '_message_label.value', '');
	}

	get processingDataCheckBoxText(): string {
		return get(this, '_processing_data_check_box_text.value', '');
	}

	get commercialInfoCheckBoxText(): string {
		return get(this, '_commercial_info_check_box_text.value', '');
	}

	get directMarketingCheckBoxText(): string {
		return get(this, '_direct_marketing_check_box_text.value', '');
	}

	get introductionToThePrivacyPolicy(): string {
		return get(this, '_introduction_to_the_privacy_policy.value', '');
	}

	get agreementText(): string {
		return get(this, '_agreement_text.value', '');
	}

	get sendButtonText(): string {
		return get(this, '_send_button_text.value', '');
	}

	get sendErrorText(): string {
		return get(this, '_send_error_text.value', '');
	}

	get sendSuccessText(): string {
		return get(this, '_send_success_text.value', '');
	}

	get sendLoadingText(): string {
		return get(this, '_send_loading_text.value', '');
	}

	get voiceRecorder(): VoiceRecorder {
		return get(this, '_voice_recorder[0]', {});
	}
}
