const SLUG = {
  BLOG: {
    pl: "blog",
    en: "blog",
  },
  PRIVACY_POLICY: {
    pl: "polityka-prywatnosci",
    en: "privacy-policy",
  },
  TERMS_OF_USE: {
    pl: "regulamin",
    en: "terms-of-use",
  },
  CASE_STUDIES: {
    pl: "case-studies",
    en: "case-studies",
  },
  COPYRIGHT: {
    pl: "prawo-autorskie-i-ochrona-wlasnosci-intelektualnej",
    en: "prawo-autorskie-i-ochrona-wlasnosci-intelektualnej",
  },
  ECOMMERCE: {
    pl: "prawo-w-ecommerce",
    en: "prawo-w-ecommerce",
  },
  LEGAL_ASSISTANCE: {
    pl: "pomoc-prawna-dla-firm",
    en: "pomoc-prawna-dla-firm",
  },
  IT_LEGAL_SERVICE: {
    pl: "obsluga-prawna-branzy-it",
    en: "obsluga-prawna-branzy-it",
  },
};

export default SLUG;
