import React, { Component } from 'react';
import get from 'lodash/get';
import { observer } from 'mobx-react';
import Layout from 'components/Layout/Layout';
import {
	observable,
	computed, action,
} from 'mobx';
import {
	SliderSection as SliderSectionModel,
	Slide as SlideModel,
} from 'models';
import Slide from 'blocks/SliderSection/Slide/Slide';
import BackButton from 'components/Buttons/Arrow/BackButton';
import NextButton from 'components/Buttons/Arrow/NextButton';
import { Bind } from 'lodash-decorators';
import styles from './SliderSection.module.scss';
import { setColor } from 'components/Background/Background';
import { isMobile } from "react-device-detect";
import Button from 'components/Buttons/Button/Button';
import { console } from 'window-or-global';
import LinkParser from 'components/LinkParser/LinkParser';

export interface ISliderSectionProps {
	data: SliderSectionModel;
}

export interface ISlideSectionState {
	mobile: boolean,
}

@observer
export default class SliderSection extends Component<ISliderSectionProps, any> {
	@observable slides: SlideModel[] = [];

	@observable currentSlideIndex = 0;

	@observable mobile = isMobile

	slideRef: Slide | null = null;

	constructor(props: ISliderSectionProps) {
		super(props);
		this.slides = props.data.slides;
		this.state = {
			images: [],
		}
	}

	componentDidMount = () => {
		this.getImagesToPreload();
	}

	getImagesToPreload = () => {
		const images = this.slides.map((slide: SlideModel) => {
			if (slide.backgroundImage) {
				return get(slide, 'backgroundImage.url');
			}
		});

		this.setState({
			images,
		});

	}

	@computed
	get currentSlide() {
		return this.slides[this.currentSlideIndex];
	}

	@Bind
	@action
	async back() {
		if (this.slideRef) {
			await this.slideRef.hideToRight();
			this.currentSlideIndex = !this.currentSlideIndex
				? this.slides.length - 1
				: this.currentSlideIndex -= 1;
			await this.slideRef.showToRight();
		}
	}

	@Bind
	@action
	async next() {
		if (this.slideRef) {
			await this.slideRef.hideToLeft();
			this.currentSlideIndex = this.currentSlideIndex === (this.slides.length - 1)
				? 0
				: this.currentSlideIndex + 1;
			await this.slideRef.showToLeft();
		}
	}

	render() {
		const {
			data: {
				headerText,
				readMoreText,
				textColor,
				backgroundAsset
			},
		} = this.props;

		return ( 
			<section className={styles.Container}  
					 style={{backgroundImage: `url(${backgroundAsset && backgroundAsset.url})`,
							color: `${setColor(textColor)}` }}
			>
				<Layout className={styles.Layout}>
					<div className={styles.Heading}>{headerText}</div>
				</Layout>

			{this.slides.length === 1 ? <>
				<Slide
					readMoreText={readMoreText}
					ref={(ref) => this.slideRef = ref}
					data={this.currentSlide}
				/>
				<Layout className={`${styles.Layout}`}>
					<LinkParser data={this.currentSlide.contactLink}>
						<Button>
							{this.currentSlide.contactLink.text}
						</Button>
					</LinkParser>
				</Layout>
			</> : <>
			{!this.mobile ? <>
						<Slide
							readMoreText={readMoreText}
							ref={(ref) => this.slideRef = ref}
							data={this.currentSlide}
						/>
				<Layout className={`${styles.Layout}`}>
					<div className={styles.Buttons}>
						<BackButton
							onClick={this.back}
							className={styles.ButtonGap}
							style={{borderColor: `${setColor(textColor)}` }}
						/>
						<NextButton
							onClick={this.next}
							style={{borderColor: `${setColor(textColor)}` }}
						/>
					</div>
				</Layout>
				</> : <div className={styles.MobileLayout}>
						<BackButton
							onClick={this.back}
							className={styles.ButtonMobileGapLeft}
							style={{borderColor: `${setColor(textColor)}` }}
						/>
						<Slide
							readMoreText={readMoreText}
							ref={(ref) => this.slideRef = ref}
							data={this.currentSlide}
						/>
						<NextButton
							onClick={this.next}
							className={styles.ButtonMobileGapRight}
							style={{borderColor: `${setColor(textColor)}` }}
						/>
				</div>}
			</>}
			</section>
		);
	}
}
