import React, { Component } from 'react';
import { BlockSection as BlockSectionModel } from 'models';
import styles from './BlockSection.module.scss';
import Layout from 'components/Layout/Layout';
import SquareBlock from 'components/SquareBlock/SquareBlock';
import get from 'lodash/get';

interface BlockSectionProps {
  data: BlockSectionModel;
}

class BlockSection extends Component<BlockSectionProps> {
  render() {
    const {
      data: { heading, blocks },
    } = this.props;

    return (
      <>
        <Layout className={styles.Container}>
          <div className={styles.HeadingBox}>
            <h2 className={styles.Heading}>{heading}</h2>
          </div>
        </Layout>
        <div className={styles.BlocksBox}>
          {blocks.map((block, i) => (
            <SquareBlock
              key={i}
              description={get(block, 'description.value', '')}
              title={get(block, 'title.value', '')}
              index={i}
            />
          ))}
        </div>
      </>
    );
  }
}

export default BlockSection as any;
