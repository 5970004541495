import ContentItemWithResolver from 'models/ContentItemWithResolver';
import get from 'lodash/get';

export default class BlockSection extends ContentItemWithResolver {
  get heading() {
    return get(this, '_heading.value', '');
  }

  get blocks() {
    return get(this, '_square_blocks', []);
  }

}
