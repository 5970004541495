import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Tag } from 'components/Tag/Tag';
import { formatArticleDate } from 'common/methods/date';
import { Bind } from 'lodash-decorators';
import NavigatorStore from 'stores/navigator.store';
import ANCHOR from 'common/consts/anchor';
import { NBSPAfterSingleLetters, underscoresToDashes } from 'common/methods/string';
import { Markup } from 'components/Markup/Markup';
import styles from './ArticleHeader.module.scss';
import CaseStudyArticle from "models/CaseStudyArticle";
import Asset from "components/Asset/Asset";
import { translatedValueList } from '../../../blocks/CaseStudyBox/utils';
import CaseStudiesStore from "../../../stores/caseStudies.store";
import { LawTag } from 'components/LawTag/LawTag';
import { DropDownValue } from "models/DropDownValue";
import ArticleRichTextParser from 'components/RichTextParser/ArticleRichTextParser';

export interface ArticleContentProps {
	navigatorStore: NavigatorStore;
	className?: string;
	data: CaseStudyArticle;
	showImage?: boolean;
	showDate?: boolean;
	caseStudiesStore: CaseStudiesStore;
}

@inject('navigatorStore', 'caseStudiesStore')
@observer
class ArticleHeader extends Component<ArticleContentProps> {

	static defaultProps = {
		className: '',
		showImage: false,
		showDate: true,
	};

	state = {
		sectionTags: [],
		lawTags: [],
	}

	componentDidMount() {
		const { data, caseStudiesStore } = this.props;
		const sectionTags = translatedValueList(caseStudiesStore.caseStudiesSections, data.categories);
		const lawTags = translatedValueList(caseStudiesStore.caseStudiesLaw, data.lawCategories);

		this.setState({ sectionTags, lawTags });
	}

	@Bind
	onTagClick(sectionCodeName: string) {
		const {
			navigatorStore,
		} = this.props;
		const category = underscoresToDashes(sectionCodeName);

		navigatorStore.navigate(`${ANCHOR.CASE_STUDIES}/${category}`);
	}

	render() {
		const {
			data,
			className,
			showImage,
			showDate,
		} = this.props;
		const { sectionTags, lawTags } = this.state;

		return (
			<header className={`${className} ${styles.Header}`}>
				{sectionTags.map((category: DropDownValue) => (
					<span>
						<Tag className={styles.Tag}>
							{category.representation}
						</Tag>
					</span>
				))}
				{showDate && (
					<time className={styles.Date}>
						{formatArticleDate(data.publicationDate)}
					</time>
				)}
				<div className={styles.LawTags}>
					{lawTags.map((tag: DropDownValue) => (
						<LawTag>{tag.representation}</LawTag>
					))}
				</div>
				<h1 className={styles.Title}>
					<Markup
						wrap={false}
						markup={NBSPAfterSingleLetters(data.title)}
					/>
				</h1>
				<div className={styles.Client}>
					<ArticleRichTextParser
						className={styles.Content}
						kenticoContent={data.client}
					/>
				</div>
				{showImage && (
					<Asset
						className={styles.ImageContainer}
						assetClassName={styles.Image}
						data={data.image}
					/>
				)}
			</header>
		);
	}
}

export default ArticleHeader as any;
