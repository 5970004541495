import ContentItemWithResolver from 'models/ContentItemWithResolver';
import get from 'lodash/get';

export default class ShortListing extends ContentItemWithResolver {
  get heading() {
    return get(this, '_heading.value', '');
  }
  get description() {
    return get(this, '_description.value', '');
  }

  get caseStudies() {
    return get(this, '_case_studies_blocks', []);
  }

  get anchorType() {
    return get(this, '_type_of_anchor.value[0].codename', '');
  }
}
