import React, { Component } from 'react';
import { DescriptionWithLink as DescriptionWithLinkModel } from 'models';
import styles from './DescriptionWithLink.module.scss';
import LinkParser from 'components/LinkParser/LinkParser';
import Layout from 'components/Layout/Layout';
import Button from 'components/Buttons/Button/Button';

interface DescriptionWithLinkProps {
  data: DescriptionWithLinkModel;
}

export class DescriptionWithLink extends Component<DescriptionWithLinkProps> {
  render() {
    const {
      data: { description, btnLink },
    } = this.props;

    return (
      <Layout>
        <div className={styles.Container}>
          <p className={styles.Description}>{description}</p>
          <LinkParser data={btnLink}>
            <Button className={styles.BtnLink}>{btnLink.text}</Button>
          </LinkParser>
        </div>
      </Layout>
    );
  }
}

export default DescriptionWithLink as any;
