import ContentItemWithResolver from "models/ContentItemWithResolver";
import get from 'lodash/get'

export default class HeroLanding extends ContentItemWithResolver {

    get backgroundAsset() {
		return get(this, '_background_asset.value[0]', null);
	}

    get heading() {
        return get (this, '_heading', '');
    }

    get description() {
        return get(this, '_description', '')
    }

    get buttonLinks() {
        return get (this, '_button', [])
    }

}