import ContentItemWithColorPickers from 'models/ContentItemWithColorPickers';
import get from 'lodash/get';

export default class SectionWithHeader extends ContentItemWithColorPickers {
  get heading() {
    return get(this, '_header.text', '');
  }

  get description() {
    return get(this, '_description.text', '');
  }

  get image() {
    return get(this, '_image.assets[0]');
  }

  get contentReversed() {
    return get(this, '_content_reversed.value[0].codename', '');
  }

  get backgroundAnimatedElement() {
    return get(this, '_animated_background_element.value[0]', '');
  }
}
