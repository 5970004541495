import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import SEO from "blocks/SEO/SEO";
import { ROUTER_PARAM } from "common/consts/common";
import { slashesToDashes } from "common/methods/string";
import Layout from "components/Layout/Layout";
import Navbar from "blocks/Navbar/Navbar";
import CoreStore from "stores/core.store";
import WindowStore from "stores/window.store";
import ViewStore from "stores/view.store";
import { ArticleBox } from "blocks/ArticleBox/ArticleBox";
import ArticleContent from "containers/BlogArticle/ArticleContent/ArticleContent";
import FooterSection from "blocks/FooterSection/FooterSection";
import CtaContactSection from "blocks/CtaContactSection/CtaContactSection";
import ArticleHeader from "./ArticleHeader/ArticleHeader";
import styles from "./CaseStudyArticle.module.scss";
import CaseStudiesStore from "../../stores/caseStudies.store";
import BlogStore from "../../stores/blog.store";
import CaseStudyBox from "../../blocks/CaseStudyBox/CaseStudyBox";
import FourColumnsSectionWithHeader from "blocks/FourColumnsWithHeaderSection/FourColumnsWithHeaderSection";
import EmployeeContactSection from "blocks/EmployeeContactSection/EmployeeContactSection";
import get from "lodash/get";
export interface CaseStudyArticleProps {
  blogStore: BlogStore;
  caseStudiesStore: CaseStudiesStore;
  coreStore: CoreStore;
  windowStore: WindowStore;
  viewStore: ViewStore;
  slug?: string;
  match: {
    params: {
      category: string;
      slug: string;
    };
  };
  path?: string;
}

@inject(
  "coreStore",
  "windowStore",
  "viewStore",
  "caseStudiesStore",
  "blogStore"
)
@observer
class CaseStudyArticle extends Component<CaseStudyArticleProps> {
  constructor(props) {
    super(props);
    props.caseStudiesStore.getArticleList();
    props.caseStudiesStore.getArticle(props.match.params[ROUTER_PARAM.SLUG]);
    props.blogStore.getArticleList();
  }

  componentDidUpdate(prevProps) {
    const { caseStudiesStore, coreStore } = this.props;

    const slug = this.props.match.params[ROUTER_PARAM.SLUG] || "";
    const prevSlug = prevProps.match.params[ROUTER_PARAM.SLUG] || "";
    const category =
      this.props.match.params[ROUTER_PARAM.CATEGORY_CASE_STUDIES] || "";
    if (slug !== prevSlug) {
      caseStudiesStore.getArticle(slug);
    }

    if (
      caseStudiesStore.currentCaseStudy &&
      slashesToDashes(category) !==
        caseStudiesStore.currentCaseStudy.mainCategory.codename
    ) {
      coreStore.setContainerFound(false);
      caseStudiesStore.currentCaseStudy = null;
      return;
    }
  }

  render() {
    const {
      windowStore: { mediaSmallDesktop },
      coreStore: {
        data,
        translations: { global },
      },
      caseStudiesStore: {
        isCaseStudyLoading,
        caseStudiesList,
        currentCaseStudy,
      },
      blogStore: { articleList },
    } = this.props;

    const slug = this.props.match.params.slug || "";
    if (isCaseStudyLoading || !currentCaseStudy) {
      return null;
    }

    const caseStudiesTranslation = global[0].seeMoreCaseStudies;

    const otherArticles = caseStudiesList
      .filter((otherArticle) => otherArticle.id !== currentCaseStudy.id)
      .slice(0, mediaSmallDesktop ? 3 : 2);
    const otherBlogArticles = articleList.slice(0, mediaSmallDesktop ? 3 : 2);
    return (
      <div>
        {currentCaseStudy.SEO && (
          <SEO data={currentCaseStudy.SEO} slug={slug} />
        )}
        {data.navigation && <Navbar data={data.navigation} />}
        <article className={styles.Container}>
          <Layout>
            <ArticleHeader data={currentCaseStudy} showImage showDate={false} />
            <ArticleContent data={currentCaseStudy} />
          </Layout>
          <Layout>
            {data.ctaContactSection && (
              <div className={styles.CtaContact}>
                <CtaContactSection
                  data={data.ctaContactSection}
                  key={data.ctaContactSection.id}
                />
              </div>
            )}
            ;
            <section className={styles.MoreArticleBoxContainer}>
              <h1 className={styles.SectionHeader}>{caseStudiesTranslation}</h1>
              <div className={styles.ArticlesWrapper}>
                {otherArticles.map((otherArticle) => (
                  <CaseStudyBox
                    data={otherArticle}
                    className={styles.MoreArticleBox}
                    type="small"
                  />
                ))}
              </div>
            </section>
            <section className={styles.MoreArticleBoxContainer}>
              <h1 className={styles.SectionHeader}>Blog</h1>
              <div className={styles.ArticlesWrapper}>
                {otherBlogArticles.map((otherBlogArticles) => (
                  <ArticleBox
                    data={otherBlogArticles}
                    className={styles.ArticleBox}
                  />
                ))}
              </div>
            </section>
          </Layout>
        </article>
        {data.wahtWeDo && (
          <div className={styles.WhatWeDo}>
            <FourColumnsSectionWithHeader
              data={data.wahtWeDo}
              key={data.wahtWeDo.id}
            />
          </div>
        )}
        ;
        {data.contactEmployee && (
          <EmployeeContactSection
            data={data.contactEmployee}
            key={data.contactEmployee.id}
          />
        )}
        ;{data.footer && <FooterSection data={data.footer} />}
      </div>
    );
  }
}

export default CaseStudyArticle as any;
