import React, { Component } from "react";
import { FooterSection as FooterSectionModel } from 'models';
import { inject, observer } from 'mobx-react';
import Layout from 'components/Layout/Layout';
import Background from 'components/Background/Background';
import { Markup } from 'components/Markup/Markup';
import LinkParser from 'components/LinkParser/LinkParser';
import SocialMedia from "components/SocialMedia/SocialMedia";
import { NBSPAfterSingleLetters } from 'common/methods/string';
import WindowStore from 'stores/window.store';

import styles from './FooterSection.module.scss';

export interface FooterProps {
  data: FooterSectionModel;
  windowStore: WindowStore;
}

@inject('windowStore')
@observer
class FooterSection extends Component<FooterProps> {
  render() {
    const {
      windowStore,
      data: {
        description,
        linksHeader,
        links,
        servicesLinksHeader,
        servicesLinks,
        bottomLinks,
        bottomText,
        mobileBottomText,
        backgroundColor,
        textColor,
        logo,
        FindUsText,
        linkedInUrl,
        twitterUrl,
        facebookUrl
      },
    } = this.props;

    return (
      <Background
        className={styles.DefaultColors}
        data={{ backgroundColor, textColor }}
      >
        <Layout>
          <section className={styles.LayoutStyle}>
            <div className={styles.Description}>
              <div className={styles.LogoWrapper}>
                <a href='/'>
                  <img src={logo.url} alt={logo.name} className={styles.Logo} />
                </a>
              </div>
              <Markup
                className={styles.Text}
                markup={NBSPAfterSingleLetters(description)}
                type="html"
              />
            </div>
            <ul className={styles.MainLinksContainer}>
              <h3 className={styles.Header}>{linksHeader}</h3>
              {links.map((link) => (
                <li className={styles.LinkContainer} key={link.text}>
                  <LinkParser data={link} className={styles.MainLink}>
                    {link.text}
                  </LinkParser>
                </li>
              ))}
            </ul>
            <ul className={styles.MainLinksContainer}>
              <h3 className={styles.Header}>{servicesLinksHeader}</h3>
              {servicesLinks.map((link) => (
                <li className={styles.LinkContainer} key={link.text}>
                  <LinkParser data={link} className={styles.MainLink}>
                    {link.text}
                  </LinkParser>
                </li>
              ))}
            </ul>
            <ul className={styles.MainLinksContainer}>
              {bottomLinks.map((link) => (
                <li className={styles.LinkContainer} key={link.text}>
                  <LinkParser data={link} className={styles.MainLink}>
                    {link.text}
                  </LinkParser>
                </li>
              ))}
              <SocialMedia
                className={styles.SocialMedia}
                title={FindUsText}
                ttUrl={twitterUrl}
                liUrl={linkedInUrl}
                fbUrl={facebookUrl}
              />
            </ul>
            <div className={styles.BottomText}>
              {windowStore && windowStore.mediaTablet
                ? <Markup
                  markup={NBSPAfterSingleLetters(bottomText)}
                  type="html"
                  trim={false}
                />
                : <Markup
                  markup={NBSPAfterSingleLetters(mobileBottomText)}
                  type="html"
                  trim={false}
                />}
            </div>
          </section>
        </Layout>
      </Background>
    );
  }
}

export default FooterSection as any;
