import { setBackgroundAndTextColor } from 'components/Background/Background';
import Layout from 'components/Layout/Layout';
import React from 'react';
import styles from './SquareBlock.module.scss';

interface SquareBlockProps {
  title: string;
  description: string;
  index: number;
}

const SquareBlock: React.FC<SquareBlockProps> = ({
  title,
  description,
  index,
}) => {
  const {
    backgroundColor,
    textColor,
  } = setBackgroundAndTextColor(index);

  return (
    <div
      style={{ backgroundColor: backgroundColor }}
      className={styles.Container}
    >
      <h4 style={{ color: textColor }} className={styles.Title}>
        {title}
      </h4>
      <hr className={styles.Line} />
      <p style={{ color: textColor }} className={styles.Description}>
        {description}
      </p>
    </div>
  );
};

export default SquareBlock;
