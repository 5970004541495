import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect
  } from "react-router-dom";
import React, { Component } from 'react';

import { Provider, observer } from 'mobx-react';
import { observable } from 'mobx';
import { createBrowserHistory } from 'history';
import CoreStore from 'stores/core.store';
import { Bind } from 'lodash-decorators';
import LanguageService from 'services/language.service';
import View from 'containers/View/View';
import ANCHOR from 'common/consts/anchor';
import { ROUTER_PARAM } from 'common/consts/common';
import { slashesToDashes, getChildrenAnchor, getParentAnchor } from "common/methods/string";
import PageNotFound from "containers/PageNotFound/PageNotFound";
import BlogArticle from 'containers/BlogArticle/BlogArticle';
import CookiesModal from 'components/CookiesModal/CookiesModal';
import root from 'window-or-global';
import LANGUAGES from "common/consts/languages";
import CaseStudyArticle from "./containers/CaseStudyArticle/CaseStudyArticle";

const AnyProvider = Provider as any;
@observer
export default class App extends Component {
	@observable selectedValue;
	private coreStore;
	browserHistory;

	constructor(props) {
		super(props);
		this.selectedValue = LanguageService.getSelectedBasenameValue();
		this.browserHistory =  createBrowserHistory({
			basename: this.selectedValue,
		});
		this.coreStore = new CoreStore(this.browserHistory);
	}

	componentDidMount() {
		this.browserHistory.listen(location => {
			this.onRouteChanged(location)
		})
	}

	componentWillUnmount() {
		this.browserHistory.unlisten()
	}


	@Bind
	onRouteChanged(location) {
		const { blogStore, caseStudiesStore } = this.coreStore;
		const currentLanguagePathName = LanguageService.getCurrentLanguagePathName();
		if (root.location.pathname !== currentLanguagePathName && currentLanguagePathName !== undefined) {
			//  root.location.replace(currentLanguagePathName);
		}

		const checkListing = getParentAnchor(location.pathname);
		const currentCategory = getChildrenAnchor(location.pathname);

		if (checkListing === ANCHOR.BLOG) {
			if (currentCategory) {
				blogStore.validateCategory(slashesToDashes(currentCategory));
				blogStore.setFilter(slashesToDashes(currentCategory));
			} else {
					blogStore.clearFilter();
							this.coreStore.setContainerFound(true);
					}
		}

		if (checkListing === ANCHOR.CASE_STUDIES) {
			if (currentCategory) {
				caseStudiesStore.validateCategory(slashesToDashes(currentCategory));
				caseStudiesStore.setFilter(slashesToDashes(currentCategory));
			} else {
					caseStudiesStore.clearFilter();
							this.coreStore.setContainerFound(true);
					}
		}
		root.scrollTo(0, 0);
		this.coreStore.setUrlHistory();
	}

	render() {

		return (
			<AnyProvider
				coreStore={this.coreStore}
				windowStore={this.coreStore.windowStore}
				viewStore={this.coreStore.viewStore}
				blogStore={this.coreStore.blogStore}
				caseStudiesStore={this.coreStore.caseStudiesStore}
				navigatorStore={this.coreStore.navigatorStore}
				globalMetaTagsStore={this.coreStore.globalMetaTagsStore}
			>
				{this.coreStore.initialCoreDataFetchDone && (
				<>
					<Router history={this.browserHistory} >
						{!this.coreStore.containerFound && (<PageNotFound/>)}
						<Switch>
							<Route exact path={`${ANCHOR.HOME}`} component={View}  />
							<Route exact path={`/${LANGUAGES.get('PL')}`}>
							  <Redirect to={`${ANCHOR.HOME}`} component={View}/>
						  </Route>
							<Route exact path={`/${LANGUAGES.get('EN')}`} component={View}  />
							<Route exact path={`/${LANGUAGES.get('EN')}/:${ROUTER_PARAM.SLUG}`} component={View}  />

							<Route exact path={`/:${ROUTER_PARAM.SLUG}`} component={View}  />
							<Route exact path={`${ANCHOR.HOME}:${ROUTER_PARAM.CATEGORY}/:${ROUTER_PARAM.SLUG}`}  component={View}  />
							<Route exact path={`${ANCHOR.BLOG}/:${ROUTER_PARAM.CATEGORY}?/:${ROUTER_PARAM.SLUG}?`}  component={BlogArticle}  />
							<Route exact path={`${ANCHOR.CASE_STUDIES}/:${ROUTER_PARAM.CATEGORY_CASE_STUDIES}?/:${ROUTER_PARAM.SLUG}?`}  component={CaseStudyArticle}  />
						</Switch>
					</Router>
					<CookiesModal />
				</>
				)}
			</AnyProvider>
		);
	}
	// /:slug?/:category?
	// /blog/:category?/:slug?
}

