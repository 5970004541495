import ContentItemWithResolver from 'models/ContentItemWithResolver';
import get from 'lodash/get';

export default class DescriptionWithLink extends ContentItemWithResolver {
  get description() {
    return get(this, '_description.value', '');
  }

  get btnLink() {
    return get(this, '_button[0]', null);
  }
}
