export enum ROUTER_PARAM {
	SLUG = 'slug',
	CATEGORY = 'category',
	CATEGORY_CASE_STUDIES = 'category_case_studies'
}

export const DIRECTION = {
	TOP: 'top',
	BOTTOM: 'bottom',
	LEFT: 'left',
	RIGHT: 'right',
};

export const COLOR = {
	WHITE: 'white',
	PRIMARY: 'primary',
	SECONDARY: 'secondary__default_text_color_',
	SMOKY: 'smoky',
	DEFAULT: '',
};

export const COLOR_VALUES = {
	WHITE: '#FFFFFF',
	PRIMARY: '#6450D6',
	SECONDARY: '#2F2F2F',
	SMOKY : '#F2F2F2',
};

export enum FILTER_TYPE {
	SECTION_CATEGORY = 'caseStudiesSections',
	LAW_CATEGORY = 'caseStudieslaw'
}
